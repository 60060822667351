import React from "react"
import 'uikit/dist/css/uikit.css'
import Layout from "../layouts/index"
import { brands } from "../utils/information.js";
import IceBrandComponent from "../components/ice-brand-component/ice-brand-component.js";


const Brands = () => {


  return (
    <Layout
      SEOTitle={"Our Brands - ICE"}
      SEODescription={"Our Brands - ICE"}
      footerDisclaimer={""}>

      <IceBrandComponent props={brands} />

    </Layout>
  )

}

export default Brands