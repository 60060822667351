import React from "react"
import "./ice-brand-component.scss"



const IceBrandComponent = ({ props }) => {

    return (
        <div>
            <div className="brand-comp-container">
                <div className="title-content" uk-scrollspy="cls: uk-animation-slide-left; target: .title; delay: 500;">
                    <h1 className="title">{props.title}</h1>
                </div>

                {props.content.map((item, index) => {
                    return (
                        <div className="component-body">
                            <div className="xs-logo-container"><img src={item.logo} className="xs-logo" /></div>
                            <div className="content">
                                <div className="left-side"><img src={item.leftImage} className="left-side-img" /></div>
                                <div className="information">
                                    <img src={item.logo} className="xl-logo" />
                                    <div className="des">
                                        <p className="description">{item.text}</p>
                                    </div>
                                    <a href={item.ctaUrl}>{item.ctaLabel}</a>
                                </div>
                            </div>

                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default IceBrandComponent